<template>
    <v-container>
        <v-row class="text-center" no-gutters>
            <v-col cols="12">
                <v-img alt="PARKING.COM" class="my-3" height="42" contain src="@/assets/logo_huge_drk.png" />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-card class="mx-auto rounded-lg flexCard" max-width="374" height="100%" style="height: 85vh;">
                    <v-dialog v-model="loading" persistent>
                        <v-card class="pa-4">
                            <v-progress-circular indeterminate color="red"></v-progress-circular>
                            <span class="ml-4" style="font-size: 14px">Please wait...</span>
                        </v-card>
                    </v-dialog>
                    <template slot="progress">
                        <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
                    </template>
                    <div style="position: relative">
                        <!-- <v-skeleton-loader
                                      v-show="!zoneDetails.hasOwnProperty('image')"
                                        height="260px"
                                          class="rounded-t-lg"
                                            type="image"
                                              ></v-skeleton-loader>-->
                        <v-img class="rounded-t-lg" max-height="150px" style="filter: brightness(50%)" :src="zoneDetails.hasOwnProperty('image') &&
                            zoneDetails.image != '' &&
                            zoneDetails.image != null
                            ? zoneDetails.image
                            : zoneImgURL
                            " :lazy-src="zoneDetails.hasOwnProperty('image') &&
                                zoneDetails.image != '' &&
                                zoneDetails.image != null
                                ? zoneDetails.image
                                : zoneImgURL
                                " alt="Parking.com garage">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <div class="booking_header white--text text-left mr-4">
                            Welcome to
                            {{
                                zoneDetails.hasOwnProperty("zoneName")
                                    ? zoneDetails.zoneName
                                    : ""
                            }}
                        </div>
                    </div>
                    <v-card-text class="pa-0">
                        <v-form ref="detailsForm">
                            <v-container fluid pa-0>
                                <v-row no-gutters class="pt-2 information pa-0">
                                    <v-col cols="2" class="align-self-center">
                                        <v-img src="@/assets/list_divider_step.imageset/list_divider_step.png"
                                            width="45px" v-if="!infoFlag">
                                            <div class="row text-center align-self-center pl-5">
                                                <div class="col-12 text-center">
                                                    <span style="font-size:large;font-weight:bold;color:white">1</span>
                                                </div>
                                            </div>
                                        </v-img>
                                        <v-img
                                            src="@/assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                                            width="45px" v-if="infoFlag">
                                            <div class="row text-center pl-5">
                                                <div class="col-12 text-center">
                                                    <span style="font-size:larger;font-weight:bold;color:white">✓</span>
                                                </div>
                                            </div>
                                        </v-img>
                                    </v-col>
                                    <v-col cols="10" class="mt-3">
                                        <p style="color:#F2555C;font-weight:bolder">Your Information</p>
                                    </v-col>
                                </v-row>
                                <Transition name="fade">
                                    <v-row no-gutters v-if="templateFlags == 'create'">
                                        <v-col cols="12" class="px-4 pt-5 text-center color-black"
                                            style="font-size: 15px; color: black; font-weight: 500;">
                                            <p>Enter your phone number to get started </p>
                                        </v-col>
                                        <v-col cols="12" class="px-4  text-center color-black" style="font-size: 12px">
                                            <v-row no-gutters class="py-2">
                                                <v-col cols="1" class="text-right align-self-center">
                                                    <v-icon class="material-icons-outlined">send_to_mobile</v-icon>
                                                </v-col>
                                                <v-col cols="11" class="px-2">
                                                    <VuePhoneNumberInput v-model="contactObj.phoneNumber" size="md"
                                                        @update="onContactInput" show-code-on-list
                                                        :default-country-code="defaultCountryCode"
                                                        :color="contactOptions.color"
                                                        :valid-color="contactOptions.validColor"
                                                        :error-color="contactOptions.errorColor"
                                                        :border-radius="contactOptions.contactBorderRadius"
                                                        :error="!isContactValid" required
                                                        :translations="contactOptions.translations" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters class="pa-4" v-if="templateFlags == 'edit'">
                                        <v-col cols="12" class="px-4 pt-5 text-center color-black"
                                            style="font-size: 15px; color: black; font-weight: 500;">
                                            <p>Enter the code </p>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-otp-input plain length="6" type="number" v-model="otpValue"
                                                @finish="onOTPInput"></v-otp-input>
                                        </v-col>
                                    </v-row>
                                </Transition>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="pt-0 mt-4 pb-10 text-center">
                        <Transition name="fade" >
                            <v-row no-gutters v-if="templateFlags == 'create'" class="mt-11">
                                <v-col cols="12" class="px-2 ">
                                    <v-btn rounded elevation="20" :loading="requestBtnLoading"
                                        class="add_licence_plate_btn white--text" :disabled="requestOTPDisabled"
                                        @click="sendOTP()">REQUEST
                                        CODE</v-btn>
                                </v-col>
                            </v-row>
                            <v-row no-gutters v-if="templateFlags == 'edit'">
                                <v-col cols="12" class="px-2 ">
                                    <v-btn rounded elevation="20" class="add_licence_plate_btn white--text"
                                        :loading="otpVerificationLoading" :disabled="submitOTPDisabled"
                                        @click="verifyOTP()">SUBMIT</v-btn>
                                </v-col>
                                <v-col cols="12" class="px-2 mt-4 text-center">
                                    <v-btn rounded elevation="20" width="50%" class="white--text" color="primary"
                                        @click="cancelOtpVerf()">Cancel</v-btn>
                                </v-col>
                                <v-col cols="12" class=" text-center">
                                    <p class="pt-10" v-if="otpTimer != 0 && otpTimer != undefined && otpTimer != null">
                                        Please
                                        wait for <span style="color: #F2555C;cursor:pointer;">{{
                                            otpTimer }} secs</span> to resend OTP</p>
                                    <p class="pt-10" v-if="otpTimer == 0">
                                        Didn't receive code ? <span style="color: #F2555C;cursor:pointer;" text
                                            @click="resendOTP()">
                                            Request Another</span>
                                    </p>
                                </v-col>
                            </v-row>
                        </Transition>
                    </v-card-actions>
                </v-card></v-col>
        </v-row>
        <!-- <out-standing-payments-view :outStandingPayments="outStandingPayments"
            :outStandingBookingId="outStandingBookingId" :iosDevice="iosDevice" typeOfBooking="OutStandingODV"></out-standing-payments-view> -->
        <v-dialog v-model="alertDialog" persistent max-width="290">
            <v-card class="pa-0">
                <v-card-title class="justify-center" style="position: relative">
                    <v-icon x-large color="green accent-2">{{ alertIcon }}</v-icon>
                </v-card-title>
                <v-card-text class="text-center mt-5">
                    <span style="font-size: 20px">{{ alertMsg }}</span>
                </v-card-text>
                <v-card-actions class="text-center pb-6">
                    <v-container pa-0 fluid>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                                    @click="alertDialog = false">Close</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import APIHelper from "../apiHelper";
import VuePhoneNumberInput from "vue-phone-number-input";
// import OutStandingPaymentsView from "@/components/OutStandingPaymentsView.vue";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { getURLParamValue } from "@/utils/util";
export default {
    data: () => ({
        // outStandingBookingId: "",
        otpVerificationLoading: false,
        iosDevice: false,
        // outStandingPayments: false,
        alertIcon: "mdi-check-circle",
        alertDialog: false,
        alertMsg: "",
        otpTimer: 0,
        requestBtnLoading: false,
        otpValue: '',
        dummy: null,
        contactObj: {},
        template: 'create',
        isContactValid: true,
        loading: false,
        contactOptions: {
            color: "#0288D1",
            validColor: "#0288D1",
            contactBorderRadius: 0,
            errorColor: "#FF5252",
            defaultCountry: "US",
            translations: {
                phoneNumberLabel: "Contact No",
            },
        },

    }),
   async mounted() {
        var self = this;
        this.otpVerificationLoading = false;
        this.getMobileOperatingSystem() === 'iOS' ? this.iosDevice = true : this.iosDevice = false;

        self.$store.commit('SET_TEMPLATE_FLAGS', 'create')
        let searchParams = window.location.search.split("=");
            let searchParamKey = searchParams.length > 0 ? searchParams[0] : "";
            let searchParamValue = searchParams.length > 0 ? searchParams[1] : "";
            switch (searchParamKey) {
                case "?zcode":
                    await self.getZoneDetails(searchParamKey, searchParamValue);
                    break;
                case "?gateid":
                    //await self.getZoneDetails(searchParamKey, searchParamValue);
                    break;
            }
    },
    beforeRouteEnter(to, from, next) {
        next(async (vm) => {
            let searchParams = window.location.search.split("=");
            let searchParamKey = searchParams.length > 0 ? searchParams[0] : "";
            let searchParamValue = searchParams.length > 0 ? searchParams[1] : "";
            switch (searchParamKey) {
                case "?zcode":
                    await vm.getZoneDetails(searchParamKey, searchParamValue);
                    break;
                case "?gateid":
                    //await self.getZoneDetails(searchParamKey, searchParamValue);
                    break;
            }
        })
    },
    components: {
        VuePhoneNumberInput,
        //  OutStandingPaymentsView
    },
    computed: {
        defaultCountryCode() {
            return Object.hasOwnProperty.call(this.contactObj, 'countryCode') && this.contactObj.countryCode != null && this.contactObj.countryCode != undefined && this.contactObj.countryCode != "" ? this.contactObj.countryCode : this.contactOptions?.defaultCountry
        },
        submitOTPDisabled() {
            return this.otpValue == "" || this.otpValue == null || this.otpValue == undefined || this.otpValue.length < 6
        },
        requestOTPDisabled() {
            return this.contactObj == null ||
                !this.contactObj.isValid ||
                this.contactObj.phoneNumber == "" ||
                this.contactObj.phoneNumber == null
        },
        ...mapGetters({
            odDetails: "getterOdDetails",
            casinoDetails: 'getterCasinoDetails',
            zoneDetails: "getterZoneDetails",
            templateFlags: 'getterTemplateFlags'
        }),
        infoFlag() {
            return this.contactObj == null ||
                !this.contactObj.isValid ||
                this.contactObj.phoneNumber == "" ||
                this.contactObj.phoneNumber == null ||
                this.licencePlate === "" ||
                this.licencePlate === null ? false : true
        },
    },
    methods: {
        /**
   * @method getZoneDetails fetch the zone details and commit SET_ZONE_DETAILS mutation.
   */
        async getZoneDetails(searchParamKey, searchParamValue) {
            this.loading = true
            try {
                var zoneDetails = await APIHelper(
                    "GET",
                    "/api/v1/user/config" + searchParamKey + "=" + searchParamValue
                );
                this.$store.commit("SET_ZONE_DETAILS", zoneDetails.data?.zone);
                this.loading = false
                var getMySpotEvent = await APIHelper("POST", "/api/v1/getMySpotEvent", { zcode: zoneDetails?.data?.zone?.zcode })
                this.$store.commit("SET_MYSPOT_DETAILS", getMySpotEvent?.data?.events);

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },
        onOTPInput() {
            this.verifyOTP()
        },
        // async getBookingDetails(bookingId) {
        //     this.loading = true;
        //     try {
        //         var bookingDetails = await APIHelper(
        //             "GET",
        //             "/api/v1/booking/config/" + bookingId
        //         );
        //         this.$store.commit(
        //             "SET_BOOKING_DETAILS",
        //             bookingDetails.data?.data ? bookingDetails.data.data : null
        //         );
        //         if (this.bookingDetails?.outStandingPaymentDetails?.status) {
        //             this.outStandingPayments = this.bookingDetails
        //                 ?.outStandingPaymentDetails?.status && this.bookingDetails?.outStandingPaymentDetails?.status == 'BLOCKED' && this.bookingDetails?.zone?.showOutstandingPayment == 1
        //                 ? true
        //                 : false;
        //         }
        //         this.loading = false
        //     } catch (error) {
        //         this.loading = false
        //         console.log(error);
        //     }
        // },
        getMobileOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;
            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }
            return "unknown";
        },
        cancelOtpVerf() {
            this.$store.commit("SET_TEMPLATE_FLAGS", 'create')
            this.otpValue = ''
        },
        // async checkOutStandingPayments() {
        //     try {
        //        this.requestBtnLoading = true;
        //         let zcode = getURLParamValue("zcode");

        //         let details = await APIHelper("POST", "/api/v1/prepaid/openTransaction", {
        //             contact: this.contactObj?.formattedNumber,
        //             zcode: zcode
        //         });
        //        this.requestBtnLoading = false;

        //         if (details?.data?.data?.outStandingPaymentDetails?.status) {
        //        this.requestBtnLoading = false;

        //             this.outStandingBookingId = details?.data?.data?.outStandingPaymentDetails?.bid;
        //             await this.getBookingDetails(details?.data?.data?.outStandingPaymentDetails?.bid);
        //             this.outStandingPayments = details?.data?.data?.outStandingPaymentDetails?.status && details?.data?.data?.outStandingPaymentDetails?.status == 'BLOCKED' && this.zoneDetails.showOutstandingPayment == 1
        //                 ? true
        //                 : false;
        //                 this.outStandingPayments ?  this.verifyOTP() : ""
        //         }
        //         else {

        //             this.verifyOTP      ();
        //         }


        //     }
        //     catch (error) {
        //        this.requestBtnLoading = false;

        //         console.log(error)
        //     }
        // },
        async verifyOTP() {
            this.otpVerificationLoading = true;
            // var otpVerification = await APIHelper("POST", "/api/v1/verifyMobileOTP", {
            //     mobileNo: this.contactObj?.formattedNumber,
            //     mobileOTP: this.otpValue,
            // })
            // if (otpVerification?.data?.status == true) {
                let zcode = getURLParamValue("zcode");
                localStorage.setItem("odDetails", JSON.stringify({ contact: this.contactObj, otpVerified: true }));
                window.location.replace(window.location.origin + '?zcode=' + zcode + '#/ondemand')
                this.otpVerificationLoading = false;
            // }
            // else {
            //     this.alertIcon = "mdi-alert-outline"
            //     this.alertMsg = otpVerification?.data?.message ? otpVerification?.data?.message : "";
            //     this.alertDialog = true;
            //     this.otpVerificationLoading = false;
            // }

        },
        onContactInput(contactDetails) {
            this.isContactValid = contactDetails?.isValid;
            this.contactObj = Object.assign({}, contactDetails);
        },
        async resendOTP() {
            let zcode = getURLParamValue("zcode");
            localStorage.setItem("otpTime", 24)
            var otpDetails = await APIHelper(
                "POST",
                "/api/v1/sendMobileOTP",
                {
                    mobileNo: this.contactObj?.formattedNumber,
                    zcode: zcode,
                }
            );
            if (otpDetails.status && otpDetails?.data?.message == 'OTP sent successfully') {
                var self = this;
                const interval = setInterval(() => {
                    let endTime = localStorage.getItem("otpTime")
                    if (endTime > 0) {
                        self.otpTimerCount()
                    } else {
                        self.resetOtpTime()
                        clearInterval(interval)
                    }
                }, 1000);
            }
            else {
                this.alertIcon = "mdi-alert-outline"
                this.alertMsg = otpDetails?.data?.message ? otpDetails?.data?.message : "";
                this.alertDialog = true;
            }

        },
        resetOtpTime() {
            localStorage.setItem("otpTime", 0)
            this.otpTimer = 0
        },
        otpTimerCount() {
            localStorage.setItem("otpTime", localStorage.getItem("otpTime") - 1);
            this.otpTimer = localStorage.getItem("otpTime")
        },
        async sendOTP() {
            this.requestBtnLoading = true;
            let zcode = getURLParamValue("zcode");
            var otpDetails = await APIHelper(
                "POST",
                "/api/v1/sendMobileOTP",
                {
                    mobileNo: this.contactObj?.formattedNumber,
                    zcode: zcode,
                }
            );
            if (otpDetails.status) {
                // this.template = 'edit'
                this.$store.commit('SET_TEMPLATE_FLAGS', 'edit')
                this.requestBtnLoading = false;
            }
            else {
                this.alertIcon = "mdi-alert-outline"
                this.alertMsg = otpDetails?.data?.message ? otpDetails?.data?.message : "";
                this.alertDialog = true;
                this.requestBtnLoading = false;
            }
            this.requestBtnLoading = false;
        }
    }
}

</script>
<style>
.information {
    background-color: #edf1f4;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}
.add_licence_plate_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.booking_header {
    position: absolute;
    bottom: 30px;
    left: 16px;
    font-size: 18px;
    font-weight: bolder;
}

.country-selector.md.has-value .country-selector__input {
    border: none;
    border-bottom: 1px solid rgb(118, 118, 118);
}

.input-tel.md .input-tel__input {
    border: none;
    border-bottom: 1px solid rgb(118, 118, 118);
    margin-left: 2px;
}

.input-tel.is-focused.has-error .input-tel__input {
    box-shadow: none !important;
}

.input-tel.is-focused.is-valid .input-tel__input {
    box-shadow: none !important;
}

.v-otp-input .v-input input[type="number"] {
    padding: 8px 0 8px 0 !important;
}

.flexcard {
    display: flex;
    flex-direction: column;
}
</style>